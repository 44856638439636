
import { Component, Vue } from 'vue-property-decorator';
import { getBoard } from '@/api/board';
import { getStay } from '@/api/stay';
import { getStoreInfo } from '@/api/storeInfo';
import { addRecentList } from '@/utils/localStorage';
import moment from 'moment';
import {
  addPost,
  getPost,
  updatePost,
} from '@/api/post';
import { IMenuDetail } from '@/types';
import { ElForm } from 'element-ui/types/form';

@Component({
  components: {
  },
})

export default class extends Vue {
  mounted() {
    this.getBoard();
    this.getPost();
    // this.getBusiess();
  }

  private board = {
    uid: '',
    name: '',
    categoryList: [],
  }

  private apiUrl = process.env.VUE_APP_BASE_API;

  private commonApiUrl = process.env.VUE_APP_COMMON_API;

  private loading = true;

  private busiessData: any = {
    name: '',
    introduction: '',
    tags: [],
    options: [],
    remainRoom: 0,
    inquiryTel: '',
    address: '',
    addressDetail: '',
    checkIn: '',
    checkOut: '',
    basicInfo: '',
    parkingInfo: '',
    surroundInfo: '',
    additionalPeopleInfo: '',
    refundPolicyInfo: '',
    confirmationInfo: '',
    minPrice: '',
    maxPrice: '',
    lat: '',
    lng: '',
    avgScore: '0.0',
    reviewCount: 0,
    business: {
      bossName: '',
      companyName: '',
      companyAddress: '',
      companyAddressDetail: '',
      greeting: '',
      profileFileUid: '',
    },
  }

  private detailQuery: any = {
    lat: '',
    lng: '',
    useDate: moment().format('YYYY-MM-DD'),
  }

  private storeInfo = {
    name: '',
    introduce: '',
    tags: [],
    address: '',
    addressDetail: '',
    lat: '',
    lng: '',
    inquiryTel: '',
    basicInfo: '',
    operationTimeInfo: '',
    equipmentInfo: '',
    parkingInfo: '',
    locationInfo: '',
    reserveUrl: '',
    distance: '',
    companyName: '',
    bossName: '',
    companyAddress: '',
    companyAddressDetail: '',
    profileFileUid: '',
    greeting: '',
    minPrice: 0,
    reviewCount: 0,
    avgScore: '0.0',
  }

  private formData: any = {
    boardUid: '74f59e08-cf5e-42ed-ae3e-781ba429d88c',
    title: '',
    content: '',
    parentUid: null,
    writer: '',
    password: '',
    categoryUid: '',
    categoryList: [],
    dataList: [],
    fileList: [],
    noticeStatus: false,
    businessIdx: null,
    stayIdx: null,
  }

  private baseUrl = process.env.VUE_APP_TARGET;

  private fileSizeLimit = 5;

  private busiessIdx = 0;

  private currentIndex = 0;

  private totalImages = 0;

  // 숙박일 때 문의하기 쿼리
  private stayName = this.$route.query.stayName;

  private stayImage = this.$route.query.stayImage;

  private stayBossName = this.$route.query.stayBossName;

  private rules = {
    categoryUid: [
      { required: true, message: '카테고리를 선택하세요.', trigger: 'blur' },
    ],
    title: [
      { required: true, message: '제목을 입력해주세요.', trigger: 'blur' },
    ],
    content: [
      { required: true, message: '내용을 입력해주세요.', trigger: 'blur' },
    ],
  }

  private categoryList = [];

  private getBoard() {
    getBoard('74f59e08-cf5e-42ed-ae3e-781ba429d88c').then((res) => {
      this.board = res.data;
    });
  }

  private handleClickInquiry() {
    if (this.formData.categoryUid !== '') {
      (this.$refs.form as ElForm).validate((valid: boolean) => {
        if (valid) {
          this.formData.categoryList.push({ uid: null, categoryUid: this.formData.categoryUid });
          if (this.$route.params.postUid) {
            updatePost(this.$route.params.postUid, this.formData).then(() => {
              this.$message.success('문의가 수정되었습니다.');
              this.$router.push({ name: 'Inquiry' });
            });
          } else {
            if (this.$route.query.businessIdx) {
              this.formData.businessIdx = this.$route.query.businessIdx;
            } else if (this.$route.query.stayIdx) {
              this.formData.stayIdx = this.$route.query.stayIdx;
            }
            addPost(this.formData).then(() => {
              this.$message.success('문의가 등록되었습니다.');
              this.$router.push({ name: 'Inquiry' });
            });
          }
        }
      });
    } else {
      this.$message.error('문의유형을 선택하세요');
    }
  }

  private getPost() {
    if (this.$route.params.postUid) {
      getPost(this.$route.params.postUid).then((res) => {
        this.loading = false;
        this.formData = {
          ...res.data,
          categoryList: [],
        };
        if (res.data.categoryList.length > 0) {
          this.formData.categoryUid = res.data.categoryList[0].categoryUid;
        }
      }).catch(() => {
        this.$router.push({ name: 'Inquiry' });
        this.$message.error('게시글을 불러오는데 실패했습니다.');
      });
    } else {
      this.loading = false;
    }
  }

  private getBusiess() {
    const param = this.$route.params;
    if (param.stayIdx) { // 숙소정보면
      this.busiessIdx = parseInt(this.$route.params.stayIdx, 10);
      this.getStayDetail();
    } else if (param.restaurantIdx) { // 외식정보면
      this.busiessIdx = parseInt(param.restaurantIdx, 10);
      this.getStoreInfoDetail();
    } else if (param.placeIdx) { // 관광정보면
      this.busiessIdx = parseInt(param.placeIdx, 10);
      this.getStoreInfoDetail();
    } else if (param.leisureIdx) { // 레저정보면
      this.busiessIdx = parseInt(param.leisureIdx, 10);
      this.getStoreInfoDetail();
    } else if (param.excitingIdx) { // 체험정보면
      this.busiessIdx = parseInt(param.excitingIdx, 10);
      this.getStoreInfoDetail();
    } else if (param.goods) { // 상품정보면
    }
  }

  // 숙소정보
  private getStayDetail() {
    getStay(this.busiessIdx, this.busiessData).then((res) => {
      this.loading = false;
      this.busiessData = res.data;
    }).catch((error) => {
      this.$message.error('숙소정보를 불러오는데 실패했습니다.');
      this.loading = false;
    });
  }

  // 관광정보
  private getStoreInfoDetail() {
    this.loading = true;
    getStoreInfo(this.busiessIdx, this.detailQuery).then((res) => {
      addRecentList(res.data, window.location.pathname);
      this.busiessData = res.data;
      this.loading = false;
    });
  }

  private goBack() {
    this.$router.go(-1);
  }

  // 슬라이드 현재페이지 숫자
  private carouselChange(index: number): void {
    if (this.busiessData.fileList.length <= index) {
      this.currentIndex -= 1;
    } else {
      this.currentIndex = index;
    }
  }

  private handleBeforeUpload(uploadFile:File, isPhoto:boolean) {
    const fileSizeLimitByMb = this.fileSizeLimit * 1024 * 1024;
    if (isPhoto) {
      const isImageFile = uploadFile.type.split('/')[0] === 'image';
      if (!isImageFile) {
        this.$message.warning('이미지 파일만 업로드 가능합니다.');
        return false;
      }
    }
    if (uploadFile.size > fileSizeLimitByMb) {
      this.$message.warning(`파일 업로드 최대용량은 ${this.fileSizeLimit}MB입니다.`);
      return false;
    }
    return true;
  }

  private handleRemoveImageFile(file: any) {
    const index = this.formData.fileList.findIndex((imgFile: any) => imgFile.fileUid === file.fileUid);
    if (index > -1) this.formData.fileList.splice(index, 1);
  }

  private handleSuccessUploadImageFile(res: any) {
    console.log(this.formData);
    this.formData.fileList.push({ fileUid: res.uid });
  }
}
